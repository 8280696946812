<template>
  <div class="page">
    <div class="page__title">
     {{ getModuleConfig("apply_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('apply_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('apply_page.content')" v-html="getModuleConfig('apply_page.content')"></div>

    <div class="search s-space-x-2 s-mb-3" v-if="isCanRedeem">
      <input
        class="search__input"
        placeholder="輸入優惠券代號"
        v-model="code"
      />
      <SharedButton
        size="xs"
        variant="primary"
        class="s-btn-bg-primary"
        @click="takeCoupon"
        block
      >
        領取
      </SharedButton>
    </div>

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <coupon-card
        v-else-if="!showLoading && coupons.length > 0"
        v-for="coupon in coupons"
        :key="coupon.id"
        :coupon="coupon"
        :show-code="false"
        @exchange="applyCoupon"
        :button-text="coupon.button_text"
      >
      </coupon-card>
      <coupon-empty v-else />
      <Pagination v-model="page" :total-page="lastPage" />
    </div>

    <coupon-modal ref="couponModal" :coupon="selectedCoupon" @confirmed="fetchCoupon"/>
  </div>
</template>

<script>
import couponApi from "@/apis/liff/v2/coupon";
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue";
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import CouponModal from "@/components/Page/Liff/CouponList/CouponModal.vue"
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import * as consts from "@/consts";
import Pagination from '@/components/Page/Liff/Shared/Pagination'
import couponMixin from "@/mixins/liff/coupon";


export default {
  mixins: [couponMixin],
  components: { CouponCard, CouponEmpty, CouponModal, Loading, SharedButton, Pagination },
  data() {
    return {
      showLoading: true,
      code: null,
      coupons: [],
      page: +this.$route.query.page || 1,
      lastPage: 1,
      selectedCoupon: {},
    };
  },
  computed: {
    isCanRedeem() {
      const type =
        this.$store.getters["liffModule/getConfig"](
          "member_center",
          "data_source.coupon_list"
        ) ?? "waltily";
      return type == "waltily";
    },
  },
  watch: {
    page() {
      this.fetchCoupon();
    },
  },
  mounted() {
    this.fetchCoupon();
  },
  methods: {
    async fetchCoupon() {
      this.showLoading = true;
      // getCoupons: 已產生優惠卷代碼，並指派給消費者，讓消費者領取，來源為 redeem_code
      // getCouponsFromBundle: 優惠卷的領取規則為直接領取，優惠卷不需要先指派給消費者，消費者可以直接看到優惠卷並領取
      //                       來源為 redeem_code_bundle
      // 因為兩邊優惠卷的來源不同，所以資料會各自經過一次正規化
      if (!this.isCanRedeem) {
        await this.getCoupons();
      } else {
        await this.getCouponsFromBundle();
      }
      this.showLoading = false;
    },
    async getCoupons() {
      try {
        const data = await couponApi.getCouponLists({
          page: this.page,
          status: "apply",
        });
        this.coupons = data.data;
        this.lastPage = data.lastPage;
      } catch (error) {
        if (error.response.data.message) {
          this.$swal.fire({
            type: "error",
            title: "錯誤",
            text: error.response.data.message,
          });
        }
      }
      this.coupons.forEach((coupon) => {
        coupon.name = coupon?.name;
        coupon.start_at = coupon?.start_at;
        coupon.end_at = coupon?.end_at;
        coupon.button_text = "我要領取";
      });
    },
    async getCouponsFromBundle() {
      const data = await couponApi.getRedeemCodeBundles({
        take_type: consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.DIRECT,
        validity_period: true,
      });
      const redeemCodeBundles = data.data;
      this.lastPage = data.lastPage;
      redeemCodeBundles.forEach((bundle) => {
        bundle.isRedeemCodeBundle = true;
        if (bundle.already_taken) {
          bundle.button_text = "已領取";
        } else {
          bundle.button_text = bundle.can_take ? "我要領取" : "已無序號";
        }
        bundle.is_enabled = bundle.button_text == "我要領取";
      });
      this.coupons = redeemCodeBundles;
    },
    async takeCoupon() {
      try {
        const coupon = await couponApi.takeCoupon(this.code);

        if (coupon.usage_type === consts.REDEEM_CODE_BUNDLE_USAGE_TYPES.IMMEDIATE) {
          this.selectedCoupon = coupon;
          this.$refs.couponModal.open();
          return;
        }

        this.showSuccess("領取成功，請至我的優惠券查看");
        this.code = null;
      } catch (error) {
        console.log(error);
        this.showFail("查無此優惠代碼");
      }
    },
    async applyCoupon(coupon) {
      if (coupon.isRedeemCodeBundle && coupon.usage_type === consts.REDEEM_CODE_BUNDLE_USAGE_TYPES.IMMEDIATE) {
        this.selectedCoupon = await couponApi.getTempCouponFromRedeemCodeBundle(coupon.id);
        this.$refs.couponModal.open();
        return;
      }

      try {
        if (coupon.isRedeemCodeBundle) {
          await couponApi.applyCouponFromRedeemCodeBundle(coupon);
        } else {
          await couponApi.applyCoupon(coupon);
        }
        this.showSuccess("領取成功");
      } catch (error) {
        console.log(error.response.data);
        this.showFail("領取失敗", error.response.data.message);
      }
      this.fetchCoupon();
    },
    showSuccess(message) {
      this.$swal.fire({
        title: message,
        type: "success",
      });
    },
    showFail(title, message) {
      this.$swal.fire({
        title: title,
        text: message,
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss">
.search {
  display: flex;

  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    width: 80%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    height: 48px;
    width: 20%;
  }
}
</style>
